import styled from '@emotion/styled';

export const SelectWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 10px 0 0 0;

  @media (min-width: 576px) {
    width: auto;
    min-width: 250px;
    margin: 0 0 0 20px;
  }

  button {
    display: block;
    position: relative;
    width: 100%;
    border: none;
    outline: none;
    background: #FFFFFF;
    border: 1px solid #555555;
    padding: 5px 40px 5px 20px;
    font-size: 1rem;
    color: #555555;
    text-align: left;
    font-weight: 700;
    cursor: pointer;

    svg {
      transform: ${({ open }) => open ? 'rotate(-90deg)' : 'rotate(90deg)'};
      fill: #555555;
      position: absolute;
      top: 50%;
      right: 14px;
      margin: -6px 0 0;
    }
  }

  ul {
    display: ${({ open }) => open ? 'block' : 'none'};
    position: absolute;
    z-index: 999;
    top: 100%;
    left: 0;
    width: 100%;
    margin: 0;
    background: #fff;
    border: 1px solid #e5e5e5;
    border-top: none;
    list-style: none;

    li {
      display: block;
      margin: 0;

      button {
        display: block;
        padding: 5px 20px;
        background: none;
        border: none;
        outline: none;
        font-size: .875rem;
        font-weight: 400;
        color: #555555;
        cursor: pointer;

        &:hover {
          background: #f5f5f5;
        }
      }
    }
  }
`
